.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-image {
  height: 2rem;
}
.home-nav {
  display: flex;
}
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login {
  border-width: 0px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-register {
  display: none;
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container01 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image1 {
  height: 2rem;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.home-container02 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: space-between;
}
.home-text03 {
  color: #040433;
  font-size: 3em;
  font-style: normal;
  text-align: left;
  font-weight: 900;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text04 {
  color: rgb(4, 4, 51);
  margin-right: var(--dl-space-space-threeunits);
}
.home-text05 {
  font-style: normal;
  font-weight: 600;
}
.home-container03 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: none;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-textinput {
  color: #040433;
  font-size: 0.75rem;
  border-color: var(--dl-color-gray-500);
  border-radius: var(--dl-radius-radius-radius4);
}
.home-button {
  color: #040433;
  font-size: 0.75rem;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  border-color: var(--dl-color-gray-500);
}
.home-button:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-gray-black);
}
.home-image2 {
  width: 300px;
  height: 300px;
  object-fit: cover;
  flex-shrink: 0;
}
.home-stats {
  width: 100%;
  height: 266px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  border-color: #040433;
  border-width: 5px;
  justify-content: center;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-stat {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-image3 {
  width: 69px;
  height: 140px;
  object-fit: cover;
}
.home-text10 {
  color: #040433;
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-weight: 700;
}
.home-text11 {
  color: var(--dl-color-gray-700);
  font-size: 0.9rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.home-stat1 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.home-image4 {
  width: 68px;
  height: 113px;
  object-fit: cover;
}
.home-text12 {
  color: #040433;
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-weight: 700;
}
.home-text13 {
  color: var(--dl-color-gray-700);
  font-size: 0.9rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.home-stat2 {
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  padding-bottom: 32px;
}
.home-image5 {
  width: 87px;
  height: 68px;
  object-fit: cover;
}
.home-text14 {
  color: #040433;
  font-size: 1.15rem;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
  font-weight: 700;
}
.home-text15 {
  color: var(--dl-color-gray-700);
  font-size: 0.9rem;
  margin-top: var(--dl-space-space-halfunit);
  text-align: center;
}
.home-banner1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.home-container04 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: space-between;
}
.home-text16 {
  color: rgb(4, 4, 51);
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text18 {
  font-style: normal;
  font-weight: 900;
}
.home-text19 {
  color: #040433;
  line-height: 1.5;
  margin-right: var(--dl-space-space-threeunits);
}
.home-text21 {
  font-style: normal;
  font-weight: 700;
}
.home-text26 {
  font-style: normal;
  font-weight: 700;
}
.home-text30 {
  font-size: 1.2em;
  font-weight: 600;
}
.home-text34 {
  font-style: normal;
  font-weight: 700;
}
.home-text36 {
  font-style: normal;
  font-weight: 700;
}
.home-image6 {
  width: 300px;
  height: 300px;
  object-fit: cover;
}
.home-banner2 {
  width: 100%;
  height: 238px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
  background-image: linear-gradient(90deg, rgb(4, 4, 51) 0.00%,rgba(210, 93, 12, 0.38) 81.00%),url("/firefly%20logistica%20578811-1500w.jpg");
  background-position: top left,center;
}
.home-text42 {
  top: 41px;
  left: 175px;
  color: #fdfdfd;
  position: absolute;
  font-size: 0.9rem;
  align-self: center;
  text-align: center;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.home-text43 {
  top: 83px;
  left: -28px;
  color: #f5f5f5;
  width: 526px;
  height: 123px;
  position: absolute;
  font-size: 3rem;
  align-self: center;
  text-align: center;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: auto;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  border-color: #040433;
  border-width: 5px;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
  background-color: #ffffff;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container05 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text44 {
  color: #040433;
  font-size: 0.9rem;
  font-family: "Inter";
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
}
.home-features-heading {
  color: #040433;
  font-size: 35px;
  font-family: "Inter";
  font-weight: 600;
  line-height: 150%;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text48 {
  font-style: normal;
  font-weight: 900;
}
.home-text50 {
  font-style: normal;
  font-weight: 900;
}
.home-container06 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: 1fr 1fr;
}
.home-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: var(--dl-size-size-maxwidth);
  min-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image7 {
  width: 25rem;
  object-fit: cover;
}
.home-container07 {
  display: flex;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: column;
}
.home-text51 {
  color: #040433;
  font-size: 3rem;
}
.home-text52 {
  color: #040433;
  margin-top: var(--dl-space-space-unit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}
.home-btn-group1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-link {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  border-color: #040433;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: #040433;
}
.home-link:hover {
  transform: scale(1.02);
}
.home-link1 {
  color: #040433;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  border-color: #040433;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link1:hover {
  transform: scale(1.02);
}
.home-hero1 {
  width: 100%;
  height: 841px;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-self: flex-end;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container08 {
  height: 611px;
  display: flex;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #040433;
  border-width: 1px;
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-text53 {
  color: #040433;
  font-size: 3rem;
}
.home-form {
  width: 295px;
  height: 248px;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
}
.home-textinput1 {
  color: #040433;
  align-self: center;
  margin-top: 10px;
  border-color: #040433;
  margin-bottom: 10px;
}
.home-textinput2 {
  color: #040433;
  align-self: center;
  margin-top: 10px;
  border-color: #040433;
  margin-bottom: 10px;
}
.home-textinput3 {
  color: #040433;
  align-self: center;
  margin-top: 10px;
  border-color: #040433;
  margin-bottom: 10px;
}
.home-textinput4 {
  color: #040433;
  align-self: center;
  margin-top: 10px;
  border-color: #040433;
  margin-bottom: 10px;
}
.home-textinput5 {
  color: #040433;
  align-self: center;
  margin-top: 10px;
  border-color: #040433;
  margin-bottom: 10px;
  padding-bottom: var(--dl-space-space-sixunits);
}
.home-button1 {
  color: #ffffff;
  align-self: flex-end;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #040433;
}


.home-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-faq1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: auto;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
  background-color: #040433;
}
.home-container09 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text54 {
  font-size: 35px;
  font-style: normal;
  font-family: "Inter";
  font-weight: 900;
  line-height: 150%;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-container10 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-image8 {
  width: 200px;
  object-fit: cover;
}
.home-nav2 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.home-nav1 {
  margin-left: 0px;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav2 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav3 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav4 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container12 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text55 {
  align-self: center;
  text-align: center;
}
.home-icon-group1 {
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.home-icon12 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-icon14 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.home-icon16 {
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .home-banner {
    flex-direction: column;
  }
  .home-container02 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text04 {
    text-align: center;
    margin-left: var(--dl-space-space-threeunits);
  }
  .home-container03 {
    justify-content: center;
  }
  .home-image2 {
    width: var(--dl-size-size-xxlarge);
    height: var(--dl-size-size-xxlarge);
  }
  .home-banner1 {
    flex-direction: column;
  }
  .home-container04 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-text19 {
    text-align: center;
    margin-left: var(--dl-space-space-threeunits);
  }
  .home-image6 {
    width: var(--dl-size-size-xxlarge);
    height: var(--dl-size-size-xxlarge);
  }
  .home-hero {
    padding: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-image7 {
    order: 2;
  }
  .home-container07 {
    align-items: center;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text51 {
    text-align: center;
  }
  .home-text52 {
    text-align: center;
  }
  .home-hero1 {
    flex-direction: column;
  }
  .home-container08 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-text53 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .home-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-nav {
    display: none;
  }
  .home-btn-group {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text04 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .home-stats {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-stat {
    padding: var(--dl-space-space-unit);
  }
  .home-text10 {
    text-align: center;
  }
  .home-stat1 {
    padding: var(--dl-space-space-unit);
  }
  .home-text12 {
    text-align: center;
  }
  .home-stat2 {
    padding: var(--dl-space-space-unit);
  }
  .home-text14 {
    text-align: center;
  }
  .home-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text19 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .home-banner2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container06 {
    grid-template-columns: 1fr;
  }
  .home-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-image7 {
    width: 80%;
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-faq1 {
    flex-direction: column;
  }
  .home-container09 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container12 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text55 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-header {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-banner {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text03 {
    font-size: 2em;
  }
  .home-container03 {
    align-items: center;
    flex-direction: column;
  }
  .home-button {
    color: #ffffff;
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
    border-color: #040433;
    background-color: #040433;
  }
  .home-image2 {
    width: var(--dl-size-size-xlarge);
    height: var(--dl-size-size-xlarge);
  }
  .home-stats {
    height: 726px;
    align-items: center;
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-image3 {
    width: 136px;
  }
  .home-image4 {
    width: 105px;
  }
  .home-image5 {
    width: 139px;
    height: 83px;
  }
  .home-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-image6 {
    width: var(--dl-size-size-xlarge);
    height: var(--dl-size-size-xlarge);
  }
  .home-banner2 {
    height: 170px;
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-text42 {
    left: 0px;
    right: 0px;
    width: auto;
    height: auto;
    margin: auto;
  }
  .home-text43 {
    left: 0px;
    right: 0px;
    width: auto;
    height: auto;
    margin: auto;
    font-size: 30px;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text44 {
    align-self: flex-start;
  }
  .home-features-heading {
    font-size: 30px;
  }
  .home-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container07 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text51 {
    font-size: 2rem;
  }
  .home-btn-group1 {
    flex-direction: column;
  }
  .home-link1 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container08 {
    height: 525px;
    position: relative;
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-sixunits);
    margin-bottom: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-sixunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-text53 {
    font-size: 2rem;
  }
  .home-button1 {
    align-self: center;
    padding-top: var(--dl-space-space-unit);
    border-color: #040433;
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-text54 {
    font-size: 30px;
  }
  .home-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container12 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text55 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
