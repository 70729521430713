.feature-card-feature-card {
  background-color: #040433;
}
.feature-card-icon {
  fill: #ffffff;
}
.feature-card-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card-text {
  color: #ffffff;
  font-size: 20px;
  text-align: left;
  font-family: "Inter";
  font-weight: 600;
  line-height: 150%;
}
.feature-card-text1 {
  color: #ffffff;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 150%;
}
.feature-card-root-class-name {
  background-color: #040433;
}
.feature-card-root-class-name1 {
  background-color: #040433;
}
.feature-card-root-class-name2 {
  background-color: #040433;
}
.feature-card-root-class-name3 {
  background-color: #040433;
}
.feature-card-root-class-name4 {
  background-color: #040433;
}
.feature-card-root-class-name5 {
  background-color: #040433;
}
.feature-card-root-class-name6 {
  background-color: #040433;
}
.feature-card-root-class-name7 {
  background-color: #040433;
}
@media(max-width: 767px) {
  .feature-card-feature-card {
    flex-direction: row;
  }
  .feature-card-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card-container {
    flex-direction: column;
  }
}
