.question1-container {
  gap: var(--dl-space-space-unit);
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.question1-text {
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  font-weight: 700;
  line-height: 150%;
}
.question1-text1 {
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 150%;
}
